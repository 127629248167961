import React from 'react'
import Marquee from 'react-fast-marquee'
import partners from '../../assets/partners.svg'

const Partners = () => {
  return (
    <section id='partners' className='flex jcc aic'>
      <Marquee>
        <img src={partners} alt="Partners" />
      </Marquee>
    </section>
  )
}

export {Partners}
