import React from 'react'

import pic1 from '../../assets/articles/pic1.jpg'
import pic2 from '../../assets/articles/pic2.jpg'
import pic3 from '../../assets/articles/pic3.jpg'
import pic4 from '../../assets/articles/pic4.jpg'

const articles = [
  {
    id: 1,
    title: 'Маркетингові Тренди',
    desc: 'Досліджуємо останні тренди в маркетингу, які визначатимуть стратегії брендів у найближчому майбутньому. Від еко-ініціатив до AI, знайте, що рухає ринком.',
    author: 'Анна Коваль',
    pic: pic1,
    link: 'https://google.com/'
  },
  {
    id: 2,
    title: 'SEO Секрети',
    desc: 'SEO не стоїть на місці, і тут ви знайдете ключі до покращення видимості вашого сайту в 2024 році. Коротко про алгоритми пошуку і як з ними працювати.',
    author: 'Максим Шевченко',
    pic: pic2,
    link: 'https://google.com/'
  },
  {
    id: 3,
    title: 'Сила Контенту',
    desc: 'Контент залишається королем у маркетинговому світі. Дізнайтеся, як створювати вміст, що залучає, взаємодіє з аудиторією і перетворюється на продажі.',
    author: 'Лідія Бережна',
    pic: pic3,
    link: 'https://google.com/'
  },
  {
    id: 4,
    title: 'Брендинг 101',
    desc: 'Ефективний брендинг — ключ до успіху. Від ідентичності бренду до емоційного зв\'язку з споживачами, ось що потрібно знати кожному маркетологу.',
    author: 'Олег Петренко',
    pic: pic4,
    link: 'https://google.com/'
  },
]

const Blog = () => {
  return (
    <section id='blog'>
      <div className="s-100"></div>
      <div className="container">
        <h2 className="title tc">Статті 🖊️</h2>
        <div className="s-20"></div>
        <p className='w-50 ma tc c-green'>Читайте наші останні статті, щоб дізнатися про нові маркетингові стратегії, кейси та аналітику.</p>
        <div className="s-40"></div>
        <div className="row">
          {articles.map((el) =>
            <div className="col-lg-6 my-4" key={el.id}>
              <div className="p-5 mx-4 card-blog flex nowr jcsb h-90">
                <a href={el.link}>
                  <img src={el.pic} alt={el.title} />
                  <div className="s-20"></div>
                  <h3 className="title c-green">{el.title}</h3>
                  <div className="s-20"></div>
                  <p>{el.desc}</p>
                  <div className="s-20"></div>
                </a>
                <em className='c-green'>{el.author}</em>
              </div>
            </div>
          )}
        </div>
        <div className="tc">
          <div className="s-40"></div>
          <a href="https://typeform.com" className='button green'>Переглянути всі</a>
        </div>
      </div>
      <div className="s-100"></div>
    </section>
  )
}

export {Blog}
