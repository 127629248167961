import React from 'react'

const About = () => {
  return (
    <section id='about' className='pt-5 flex jcc aic'>
      <div className="content">
        <div className="container tc">
          <h2 className="title c-green">Про УНКД 🌟</h2>
          <div className="s-20"></div>
          <p className='w-80 ma'>UNKD — це унікальна платформа для маркетологів та брендів, яка об'єднує найкращі практики, інструменти та знання для ефективного просування на ринку. Наша місія — створити екосистему, де кожен маркетолог зможе знайти необхідні ресурси для розвитку свого бренду або кар'єри.</p>
          <div className="s-20"></div>
          <div className="row">
            <div className="col-lg-4">
              <div className="tl">
                <p className='title-intro'>🛡️</p>
                <h3 className="title-sub c-green">Захист цифрових прав</h3>
                <p>УНКД активно займається захистом цифрових прав громадян, надаючи безкоштовні консультації та допомогу у боротьбі з кіберзлочинністю та захисті цифрових активів.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tl">
                <p className='title-intro'>🔍</p>
                <h3 className="title-sub c-green">Цифрова експертиза</h3>
                <p>Наша команда експертів працює над інноваційними рішеннями для підвищення кібербезпеки, забезпечуючи стійкість України до кіберзагроз та підтримуючи якість діджиталізації.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tl">
                <p className='title-intro'>🌐</p>
                <h3 className="title-sub c-green">Інноваційний розвиток</h3>
                <p>Ми просуваємо цифрові трансформації в урядовому та корпоративному секторах, сприяючи інтеграції новітніх технологій для національної безпеки та розвитку суспільства.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { About }
