import React from 'react'

const vacancy = [
  {
    id: 1,
    title: 'Менеджер соціальних мереж',
    desc: 'На даний час ми шукаємо талановитого та високомотивованого менеджера соціальних мереж, який приєднається до н...',
    link: 'https://buxonline.org/uk/vacancy/745'
  },
  {
    id: 2,
    title: 'DevOps інженер',
    desc: 'DevOps Engineer - приєднуйтесь до нашого гнучкого та дружнього робочого середовища! В даний час ми шукаємо талановитого та досвідченого віддаленого інженера DevOps ...',
    link: ''
  },
  {
    id: 3,
    title: 'Цифровий маркетолог',
    desc: 'Ми шукаємо досвідченого та захопленого цифрового маркетолога, щоб приєднатися до нашої віддаленої команди! Як цифровий маркетолог, ви допоможете нам розроб...',
    link: 'https://buxonline.org/uk/vacancy/845'
  }
]

const Vacancies = () => {
  return (
    <section id='vacancies'>
      <div className="container">
        <h2 className="title tc c-green">Біржа вакансій 💼</h2>
        <div className="s-20"></div>
        <p className='w-60 ma tc'>Шукайте та публікуйте вакансії в галузі маркетингу. Знайдіть вашу наступну велику можливість або талант для вашої команди.</p>
        <div className="s-80"></div>
        <div className="row">
          {vacancy.map((el) =>
            <div className="col-lg-4" key={el.id}>
              <div className="p-5 mx-3 card-vacancy flex nowr jcsb h-80">
                <div>
                  <h3 className="title c-green">{el.title}</h3>
                  <div className="s-20"></div>
                  <p>{el.desc}</p>
                  <div className="s-20"></div>
                </div>
                <a href={el.link} className="button dark">Переглянути</a>
              </div>
            </div>
          )}
        </div>
        <div className="tc">
          <div className="s-80"></div>
          <a href="https://typeform.com" className='button green'>Переглянути всі</a>
        </div>
      </div>
    </section>
  )
}

export {Vacancies}
