import React from 'react'
import book from '../../assets/marketing.png'

const Marketing = () => {
  return (

    <section id='marketing' className='h-100 flex jcc aic'>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 tc">
              <img src={book} width={330} height={440} alt="Брендбук Маркетингу 📘" style={{boxShadow: '24px 0 40px rgba(0,0,0,.25)'}} />
            </div>
            <div className="col-lg-6">
              <div className="s-100"></div>
              <div className="w-80 ma">
                <h2 className="title c-green">Брендбук CASES 🎨</h2>
                <div className="s-30"></div>
                <p className='title-sub'>Наш брендбук маркетингу — це ваш найкращий ресурс для створення ефективних маркетингових кампаній з врахуванням найкращих практик та стандартів галузі.</p>
                <div className="s-30"></div>
                <a href="https://google.com" className="button green">Завантажити</a>
              </div>
            </div>
          </div>
          <div className="s-60"></div>
        </div>
      </div>
    </section>
  )
}

export {Marketing}
