import React from 'react'
import { Nav } from '../components/Nav'
import { Intro } from './parts/Intro'
import { About } from './parts/About'
import { Center } from './parts/Center'
import { Brandbook } from './parts/Brandbook'
import { Features } from './parts/Features'
import { Marketing } from './parts/Marketing'
import { Vacancies } from './parts/Vacancies'
import { Cta } from './parts/Cta'
import { Blog } from './parts/Blog'
import { Partners } from './parts/Partners'
import { Footer } from '../components/Footer'

const Home = () => {

  return (
    <>
      <Nav />
      <Intro />
      <About />
      <Center />
      <Brandbook />
      <Features />
      <Marketing />
      <Vacancies />
      <Cta />
      <Blog />
      <Partners />
      <Footer />
    </>
  )
}

export { Home }
