import React from 'react'

const Center = () => {
  return (
    <section id='info' className='h-100 flex jcc aic'>
      <div className="content">
        <div className="container tc">
          <h2 className="title c-green">📚 Довідковий Центр UNKD</h2>
          <div className="s-20"></div>
          <p className='w-80 ma'>Наш Довідковий Центр — це краткий навігатор по світу маркетингу, брендінгу та реклами. Від базових концептів до передових стратегій — усе доступно одним кліком.</p>
          <div className="s-40"></div>
          <div className="row">
            <div className="col-lg-6">
              <div className="tl m-4">
                <h3 className="title-sub c-green">💡 Запитання про маркетинг? Ми маємо відповіді!</h3>
                <div className="s-20"></div>
                <p><strong>Швидкі відповіді: </strong>Компактно та по суті про все, що вам потрібно.</p>
                <p><strong>Актуальність: </strong>Найсвіжіші тренди та інструменти в одному місці.</p>
                <p><strong>Доступ 24/7: </strong>Вся необхідна інформація доступна в будь-який час.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tl m-4">
                <h3 className="title-sub c-green">🚀 Приєднуйтесь до Марафону!</h3>
                <div className="s-20"></div>
                <p>Хочете поглибити свої знання? Наш Марафон Практики Маркетинга — це унікальна можливість для цього. Візьміть участь та перетворіть інформацію на дію!</p>
                <div className="s-20"></div>
                <p>Готові розпочати? <a href="https://typeform.com" className='c-green'>Заповніть форму</a> і долучайтесь до марафону зараз!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { Center }
