import React from 'react'

const Intro = () => {
  return (
    <section id="top" className='h-100 flex jcc aic'>
      <div className="container tc">
        <div className="w-90 ma">
          <h1 className='title-intro'>Відкрийте Світ <span className="c-green">Маркетингових</span> Можливостей з UNKD 🚀</h1>
          <div className="s-20"></div>
          <h2 className='title-intro-sub'>Вітаємо в UNKD — вашій незамінній платформі, де збираються кращі маркетингові стратегії, інструменти та знання. Ми тут, щоб допомогти вам і вашому бренду досягти нових висот, пропонуючи все необхідне для зростання та успіху у світі маркетингу. Приєднуйтесь до нашої екосистеми сьогодні і розвивайте свій бренд та кар'єру з UNKD.</h2>
          <div className="s-20"></div>
          <div className="tc">
            <a href="https://google.com" className="button green">Розпочати Зараз 🌈</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export { Intro }
