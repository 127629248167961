import React, { useState, useEffect } from 'react'
import logo from '../logo.svg'
import live from '../assets/live.svg'

const Nav = () => {
  const [opened, setOpened] = useState(true)

  useEffect(() => {
    if (!opened) {
      document.body.classList.add('menu-active')
    } else {
      document.body.classList.remove('menu-active')
    }
  }, [opened])

  const toggleMenu = () => {
    setOpened(!opened)
  }

  return (
    <header>
      <div className='container'>
        <nav>
          <h1 id='logo' className='flex aic'>
            <a href='/' aria-label='Logotype'>
              <img src={logo} width={101} height={37} alt='UNKD' />
            </a>
            <div className="p-4">
              <img src={live} width={151} height={60} alt='ПОВЕРНИСЬ ЖИВИМ' style={{transform: 'translateY(20px)'}} />
            </div>
          </h1>
          <div className={opened ? 'burger' : 'burger open'} onClick={toggleMenu}>
            <span className='tt'></span>
            <span className='mm'></span>
            <span className='bb'></span>
          </div>
          <div className={opened ? 'menu active' : 'menu'}>
            <a href='#features' className='mx-3 px-1' onClick={toggleMenu}>
              Про марафон
            </a>
            <a href='https://typeform.com' className='mx-3 px-1' onClick={toggleMenu}>
              Долучитись
            </a>
            <a href='https://wise.com' className='button dark top' onClick={toggleMenu}>❤️  Підтримати</a>
          </div>
        </nav>
      </div>
    </header>
  )
}

export { Nav }
