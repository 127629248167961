import React from 'react'

const Features = () => {
  return (
    <section id='features' className='h-100 flex jcc aic'>
      <div className="content">
        <div className="container tc">
          <h2 className="title c-green">Освіта та Стратегії в Маркетингу 💡🗺️</h2>
          <div className="s-20"></div>
          <p className='w-80 ma'>Відкрийте стратегії для зростання та навчальні карти для швидкого освоєння маркетингу.</p>
          <div className="s-40"></div>
          <div className="row">
            <div className="col-lg-6">
              <div className="tl w-80 ma">
                <h3 className="title-sub c-green">Про Маркетинг 💡</h3>
                <div className="s-20"></div>
                <p>Відкрийте для себе основні маркетингові стратегії, які допоможуть вам вирости ваш бізнес. Від контент-маркетингу до SEO, ми покриваємо всі ключові аспекти для успіху вашого бренду.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tl w-80 ma">
                <h3 className="title-sub c-green">Карти навчання 🗺️</h3>
                <div className="s-20"></div>
                <p>Ознайомтеся з нашими інтерактивними картами навчання, які допоможуть вам швидко освоїти основні маркетингові концепції та інструменти.</p>
                <div className="s-20"></div>
              </div>
            </div>
            <div className="tc">
              <div className="s-80"></div>
              <a href="https://typeform.com" className='button dark'>🚀 Приєднуйтесь до Марафону!</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { Features }
