import React from 'react'


const Footer = () => {
  const year = new Date();
  return (
    <footer id="contact" className="w-100 h-20 flex jcc aic">
      <a href="/" className='c-light title-mini'>@{year.getFullYear()} – UNKD. Усi права захищено.</a>
    </footer>
  );
};

export { Footer }
