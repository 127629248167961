import React from 'react'
import book from '../../assets/brandbook.png'

const Brandbook = () => {
  return (

    <section id='book' className='h-100 flex jcc aic'>
      <div className="content">
        <div className="container">
          <div className="s-60"></div>
          <div className="row">
            <div className="col-lg-6">
              <div className="s-100"></div>
              <div className="w-80 ma">
                <div className="s-100"></div>
                <h2 className="title c-green">Брендбук CASES 🎨</h2>
                <div className="s-30"></div>
                <p className='title-sub'>Завантажте наш брендбук, щоб дізнатися про візуальні стандарти та правила використання бренду UNKD в ваших маркетингових матеріалах.</p>
                <div className="s-30"></div>
                <a href="https://google.com" className="button green">Завантажити</a>
              </div>
            </div>
            <div className="col-lg-6 tc">
              <div className="s-100"></div>
              <img src={book} width={330} height={440} alt="Брендбук CASES 🎨" style={{boxShadow: '24px 0 40px rgba(0,0,0,.25)'}} />
            </div>
          </div>
          <div className="s-60"></div>
        </div>
      </div>
    </section>
  )
}

export {Brandbook}
